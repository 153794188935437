import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { Box, Heading, Stack, Text } from "@chakra-ui/react";

import Layout from "../components/Layout";
import Container from "../components/Container";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImageFluid from "../components/PreviewCompatibleImageFluid";

export const TeamPostTemplate = ({ content, contentComponent, description, name, helmet, image }) => {
  const PostContent = contentComponent || Content;

  return (
    <Box py="3rem">
      {helmet || ""}
      <Container>
        <Heading as="h1" size="xl" textAlign="center">
          {name}
        </Heading>
        <Stack py="2rem" direction={{ base: "column", md: "row" }} spacing="6" align="flex-start">
          <Box width={{ base: "100%", md: "650px" }} maxW="100%">
            {image ? (
              <PreviewCompatibleImageFluid
                imageInfo={{
                  image: image,
                  alt: `Image thumbnail for ${name}`,
                }}
              />
            ) : null}
          </Box>
          <Box>
            <Text>{description}</Text>
            <PostContent className="content" content={content} />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

TeamPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const TeamPost = ({ data, ...props }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout {...props}>
      <TeamPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Team">
            <title>{`${post.frontmatter.name}`}</title>
            <meta name="description" content={`${post.frontmatter.name} - ${post.frontmatter.skill}`} />
          </Helmet>
        }
        image={post.frontmatter.image}
        name={post.frontmatter.name}
      />
    </Layout>
  );
};

TeamPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default TeamPost;

export const pageQuery = graphql`
  query TeamMemberByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        skill
        twitter
        facebook
        instagram
        image {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
